import Api from "@/service/api";

export default class DateSpectacleService {

    dateSpectacle(dateSpectacleId) {
        return Api().get("/date-spectacles/" + dateSpectacleId).then(res => this.formatRes(res.data));
    }


    formatRes(e) {
        if (e.dateSpectacle){
            e.dateSpectacle = new Date(e.dateSpectacle);
        }
        return e;
    }

}
