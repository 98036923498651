import Api from "@/service/api";

export default class SpectacleService {
    spectacles() {
        return Api().get("/spectacles").then(res => {
            res.data.forEach((e) => {
                    this.formatRes(e);
                }
            );
            return res.data;
        });
    }

    comingSpectacles() {
        return Api().get("/spectacles/coming").then(res => {
            res.data.forEach((e) => {
                    this.formatRes(e);
                }
            );
            return res.data;
        });
    }

    spectacle(spectacleId) {
        return Api().get("/spectacle/" + spectacleId).then(res => this.formatRes(res.data));
    }

    create(spectacle, tempId) {
        return Api().post("/spectacle/" + tempId, spectacle).then(res => res.data);
    }

    update(spectacle) {
        return Api().put("/spectacle/" + spectacle.spectacleId, spectacle).then(res => res.data);
    }

    delete(spectacle) {
        return Api().delete("/spectacle/" + spectacle.spectacleId).then(() => spectacle);
    }

    uploadPicture(spectacleId, picture) {
        console.log(picture)
        let formData = new FormData();
        formData.append(
            'picture',
            new Blob([picture], {
                type: 'image/jpeg',
            })
        );
        formData.append(
            'fileName', picture.name
        );
        return Api().post("/spectacle/" + spectacleId + "/picture", formData).then(res => res.data);
    }

    deletePicture(spectacleId) {
        return Api().delete("/spectacle/" + spectacleId + "/picture");
    }

    formatRes(e) {
        if (e.dateSpectacle){
            e.dateSpectacle = new Date(e.dateSpectacle);
        }
        return e;
    }

}
